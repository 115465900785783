var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-space",
        { attrs: { direction: "vertical" } },
        [
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        loading: _vm.loading,
                        title: "环境变量信息",
                        bordered: false,
                      },
                    },
                    [
                      _c(
                        "a-descriptions",
                        { attrs: { column: 2 } },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "NODE_ENV" } },
                            [_c("span", [_vm._v(_vm._s(_vm.currentEnv))])]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "VUE_APP_PREVIEW" } },
                            [_c("span", [_vm._v(_vm._s(_vm.preview))])]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "VUE_APP_HTTP_API_ENDPOINT" } },
                            [_c("span", [_vm._v(_vm._s(_vm.httpApiEndpoint))])]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "VUE_APP_HTTPS_API_ENDPOINT" } },
                            [_c("span", [_vm._v(_vm._s(_vm.httpsApiEndpoint))])]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "VUE_APP_BASE_API" } },
                            [_c("span", [_vm._v(_vm._s(_vm.baseApi))])]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "VUE_APP_BASE_WS" } },
                            [_c("span", [_vm._v(_vm._s(_vm.baseWs))])]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "VUE_APP_BAIDU_MAP_AK" } },
                            [_c("span", [_vm._v(_vm._s(_vm.baiduMapAk))])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }