<template>
  <page-header-wrapper>
    <a-space direction="vertical">
      <a-row :gutter="24">
        <a-col :span="24">
          <a-card :loading="loading" title="环境变量信息" :bordered="false">
            <a-descriptions :column="2">
              <a-descriptions-item label="NODE_ENV">
                <span>{{ currentEnv }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="VUE_APP_PREVIEW">
                <span>{{ preview }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="VUE_APP_HTTP_API_ENDPOINT">
                <span>{{ httpApiEndpoint }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="VUE_APP_HTTPS_API_ENDPOINT">
                <span>{{ httpsApiEndpoint }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="VUE_APP_BASE_API">
                <span>{{ baseApi }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="VUE_APP_BASE_WS">
                <span>{{ baseWs }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="VUE_APP_BAIDU_MAP_AK">
                <span>{{ baiduMapAk }}</span>
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </a-col>
      </a-row>
    </a-space>
  </page-header-wrapper>
</template>

<script>

export default {
  name: 'EnvInfo',
  data () {
    return {
      currentEnv: process.env.NODE_ENV,
      preview: process.env.VUE_APP_PREVIEW,
      baseApi: process.env.VUE_APP_BASE_API,
      baseWs: process.env.VUE_APP_BASE_WS,
      httpApiEndpoint: process.env.VUE_APP_HTTP_API_ENDPOINT,
      httpsApiEndpoint: process.env.VUE_APP_HTTPS_API_ENDPOINT,
      baiduMapAk: process.env.VUE_APP_BAIDU_MAP_AK
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  }
}
</script>
